@import "../../../variables.scss";

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__links {
    display: flex;
    gap: 3.25rem;

    &__link.MuiButtonBase-root.MuiMenuItem-root {
      color: $primary-black;
      padding: 0;
      &.active {
        color: $main;
      }
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-size-lg;
    align-items: center;
  }
}

@media (max-width: 550px) {
  .navbar {
    &__logo {
      width: 175px;
    }
    &__buttons-container {
      &__contact-us-button.MuiButtonBase-root {
        display: none;
      }
    }
  }
}

@media (max-width: 428px) {
  .navbar {
    &__links {
      gap: 0;
      justify-content: space-around;
    }
    &__buttons-container {
      &__contact-us-button {
        display: none;
      }
    }
  }
}
