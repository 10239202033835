// ********** Border Radius **********

$border-radius-base: 1rem;

$border-radius-none: 0;
$border-radius-md: 0.5rem;

// ********** Colors **********
$primary-black: #18181b;
$primary-gray: #667085;
$primary-white: #ffffff;
$main: #f0ad4a;
$border-primary: rgba($primary-white, 0.5);
$background-cloud: #f2f6ff;

// ********** Fonts **********
$font-size-base: 16px;

$font-size-xxs: 1.125; // 18px
$font-size-xs: 1.25rem; // 20px
$font-size-sm: 1.5rem; // 24px
$font-size-md: 2rem; // 32px
$font-size-lg: 2.5rem; // 40px
$font-size-xl: 4rem; // 64px
$font-size-xxl: 6rem; // 96px

$font-weight-xs: 300;
$font-weight-sm: 400;
$font-weight-md: 500;
$font-weight-lg: 700;

// ********** Spacings **********

$spacing-size-none: 0;
$spacing-size-xxxs: 0.0625rem; // 1px
$spacing-size-xxs: 0.125rem; // 2px
$spacing-size-xs: 0.25rem; // 4px
$spacing-size-sm: 0.5rem; // 8px
$spacing-size-md: 0.75rem; // 12px
$spacing-size-lg: 1rem; // 16px
$spacing-size-xl: 1.25rem; // 20px
$spacing-size-xxl: 1.5rem; // 24px
$spacing-size-xxxl: 2rem; // 32px

// ********** Z-Indexes **********

$z-index-sub: -1;
$z-index-none: 0;
$z-index-xxs: 1;
$z-index-xs: 10;
$z-index-sm: 50;
$z-index-md: 100;
$z-index-lg: 200;
$z-index-xl: 500;
$z-index-trump: 99999;

// ********** Box Shadows **********

$box-shadow-md: 0px 20px 20px rgba($primary-black, 0.15);
$box-shadow-lg: 0px 40px 60px rgba($primary-black, 0.15);
