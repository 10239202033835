@import "../../../variables.scss";

.navbar__buttons-container {
  .main-menu {
    padding: 5rem 1rem;
    position: absolute;
    width: 100%;
    top: -20%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.15);
    transition: top 500ms;
    z-index: 9999;
  }
  .main-menu-opened {
    @extend .main-menu;
    top: 94px;
    transition: top 500ms;

    &__item {
      position: relative;
      justify-content: center;
      margin-bottom: 2.75rem;
      font-size: 1.875rem;
      padding-bottom: 0.5rem;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: calc(50% - 1.6875rem);
        display: block;
        width: 3.375rem;
        height: 3px;
        background-color: $main;
        border-radius: 2px;
        opacity: 0;
        transition: opacity 300ms;
      }

      a {
        color: $primary-black;
        opacity: 0.5;
        text-decoration: none;
      }
    }
    &__item:hover {
      &::before {
        opacity: 1;
        transition: opacity 300ms;
      }
      a {
        opacity: 1;
      }
    }
  }
}
