@import "../../../variables.scss";

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__links {
    display: flex;
    gap: calc(100% / 10);
  }

  &__link.MuiButtonBase-root.MuiMenuItem-root {
    color: $primary-black;
    padding: 0;

    &.active {
      color: $main;
    }
  }

  &__socials {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-size-lg;
  }
}

@media (max-width: 768px) {
  .footer {
    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__links.MuiButtonBase-root.MuiMenuItem-root {
      gap: $spacing-size-none;
      justify-content: space-evenly;
    }

    &__socials {
      justify-content: center;
    }
  }
}
