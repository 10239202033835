@import "../../variables.scss";

.layout {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__navbar {
    padding-top: $spacing-size-xxxl;
    margin: $spacing-size-none auto;
    width: 88%;
    z-index: 9999;
  }

  &__main-content {
    height: 100%;
    margin-top: -94px;
  }

  &__footer {
    margin: $spacing-size-xxxl auto;
    width: 88%;
  }
}

@media (max-width: 1024px) {
  .layout {
    &__navbar {
      width: calc(100% - 3rem);
    }
  }
}

@media (max-width: 428px) {
  .layout {
    &__navbar {
      width: calc(100% - 1rem);
    }
  }
}
